import React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import { NewsList } from '@mangoart/gatsby-ui/components/ezagrar/NewsList';
import DefaultLayout from '../layout/DefaultLayout';

const JobsPage = ({ data, location }) => {
  const { pageData, siteData, news } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <PageTitle>{content.title}</PageTitle>
      {news.edges.length && <NewsList data={news} />}
      {!news.edges.length && (
        <div
          className="defaultWidth"
          style={{
            backgroundColor: '#ffffff',
            padding: '3em 0',
            fontSize: '1.5em',
          }}
        >
          Es gibt derzeit keine offenen Positionen
        </div>
      )}
    </DefaultLayout>
  );
};

export default JobsPage;

export const JobsPageQuery = graphql`
  query JobsPageQuery {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    news: allCockpitNews(filter: { published: { eq: true }, category: { in: ["Stellenausschreibungen"] } }) {
      edges {
        node {
          id
          title
          teaser
          date
          expirationdate
          teaserimage {
            childImageSharp {
              gatsbyImageData
            }
          }
          images {
            alttext
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    pageData: cockpitPageJobs {
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      content {
        title
      }
    }
  }
`;
